
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted } from "vue";

//export interface Data {}

export default defineComponent({
  name: "UnappliedCredit",
  components: {},
  setup() {
    onMounted(async () => {
      setCurrentPageTitle("Unapplied Credit");
      return;
    });

    return {};
  },
});
